.hamburger_ham__dOHse {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  transition-duration: 400ms;
}

.hamburger_ham__dOHse .hamburger_top__pZrL6 {
  stroke-dasharray: 40 130;
}

.hamburger_ham__dOHse .hamburger_middle__ctZRB {
  stroke-dasharray: 40 140;
}

.hamburger_ham__dOHse .hamburger_bottom__S_XrR {
  stroke-dasharray: 40 205;
}

.hamburger_ham__dOHse.hamburger_active__eQYEK .hamburger_top__pZrL6 {
  stroke-dasharray: 75 130;
  stroke-dashoffset: -63px;
}

.hamburger_ham__dOHse.hamburger_active__eQYEK .hamburger_middle__ctZRB {
  stroke-dashoffset: -102px;
}

.hamburger_ham__dOHse.hamburger_active__eQYEK .hamburger_bottom__S_XrR {
  stroke-dasharray: 110 205;
  stroke-dashoffset: -86px;
}

.hamburger_line__WZMbn {
  fill: none;
  stroke: #222222;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke-width: 6;
  stroke-linecap: round;
}

.hamburger_ham__dOHse.hamburger_active__eQYEK .hamburger_line__WZMbn {
  stroke-width: 5;
}

